// NPM Modules
import Swiper from "swiper";
import { Navigation, Controller, Manipulation, Pagination } from "swiper/modules";

window.Swiper = Swiper;

// Cusom Functions
// import { screens } from "../modules/functions";

document.addEventListener("DOMContentLoaded", function () {
    // Set Sliders
	setSwiperLots();
});

function setSwiperLots() {
    document.querySelectorAll('.swiper-product')?.forEach(el => {
        const buttonPrev = document.getElementById('swiper-button-prev-' + el.id);
        const buttonNext = document.getElementById('swiper-button-next-' + el.id);
        const pagination = document.getElementById('swiper-pagination-' + el.id);

        new Swiper (el, {
            modules: [Navigation, Manipulation, Pagination],
			grabCursor: true,
            watchSlidesProgress: true,
            spaceBetween: 12,
            speed: 1000,
            pagination: {
				el: pagination,
				type: "fraction",
			},
            breakpoints: {
                640: {
                    slidesPerView: 2.2,
                },
                768: {
                    slidesPerView: 2.5,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                    // slidesPerGroup: 3,
                    // speed: 1600
                },
                1280: {
                    slidesPerView: 4,
                    spaceBetween: 16,
                    // slidesPerGroup: 4,
                    // speed: 1600
                },
                1536: {
                    slidesPerView: 5,
                    spaceBetween: 16,
                    // slidesPerGroup: 4,
                    // speed: 1600
                },
            },
            navigation : {
                prevEl: buttonPrev,
                nextEl: buttonNext,
                // hiddenClass: "opacity-50",
            },
        })
    })
}